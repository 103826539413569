import React, { PureComponent } from "react"
import moment from "moment"
import Tooltip from "js/includes/components/Tooltip"
import { compose, pluck, filter, includes, join, cond, equals, always, T } from "ramda"
import {
  localized,
  getTimeFromSplit,
  timeZone as getTimeZoneFromWindow,
  time,
  getTimeZone,
} from "js/includes/common/utils"
import { scheduleTypeMap } from "js/includes/common/schedule"

const ScheduleSummaryDaily = ({ frequency, time, timeZone }) => {
  return (
    <div className="flex-full">
      <h4>{`${frequency} @ ${time} ${timeZone}`}</h4>
    </div>
  )
}

const ScheduleSummaryHourly = ({ frequency, time, timeZone }) => {
  return (
    <div className="flex-full">
      <h4>{`${frequency} @ ${time} ${timeZone}`}</h4>
    </div>
  )
}

const ScheduleSummaryMonthly = ({ frequency, time, dayOfMonth, timeZone }) => {
  const day = dayOfMonth === 0 ? localized("Last day") : dayOfMonth
  return (
    <div className="flex-full">
      <h4>{`${frequency}, ${day} @ ${time} ${timeZone}`}</h4>
    </div>
  )
}

const ScheduleSummaryFrequency = ({ frequency }) => {
  return (
    <div className="flex-full">
      <h4>{`${frequency}`}</h4>
    </div>
  )
}

const getAgentTime = (scheduleHour, scheduleMinute) => {
  return time(moment(`${scheduleHour}:${scheduleMinute}`, "H:m"))
}

const getIntervalTime = ({ scheduleHour, scheduleMinute, scheduleInterval, repeatsEveryCount }) => {
  return scheduleHour || scheduleInterval === "HOURLY"
    ? localized("{{count}} Hour(s)", { count: scheduleHour || repeatsEveryCount })
    : localized("{{count}} Minutes(s)", { count: scheduleMinute || repeatsEveryCount })
}

class ScheduleSummaryWeekly extends PureComponent {
  days = [
    { value: "SUN", text: localized("Sunday") },
    { value: "MON", text: localized("Monday") },
    { value: "TUE", text: localized("Tuesday") },
    { value: "WED", text: localized("Wednesday") },
    { value: "THU", text: localized("Thursday") },
    { value: "FRI", text: localized("Friday") },
    { value: "SAT", text: localized("Saturday") },
  ]

  getWeekDays = daysOfWeek => {
    const getDayNames = compose(
      join(", "),
      pluck("text"),
      filter(d => includes(d.value, daysOfWeek)),
    )
    const weekDays = getDayNames(this.days)
    return weekDays ? weekDays : localized("No days selected")
  }

  render() {
    const { frequency, time, daysOfWeek, timeZone } = this.props
    return (
      <div className="flex-full">
        <div className="weekly-schedule">
          <h4>{`${frequency} @ ${time} ${timeZone}`}</h4>
          <div className="weekly-schedule-info">
            <Tooltip output={this.getWeekDays(daysOfWeek)} />
          </div>
        </div>
      </div>
    )
  }
}

const ScheduleSummaryOnActionTriggered = ({ actionText }) => {
  return (
    <div className="flex-full">
      <h4>{actionText}</h4>
    </div>
  )
}

const EmptySummary = () => {
  return <div className="flex-full" />
}

export const TaskScheduleSummary = ({ schedule, visibleTimeZone = true }) => {
  const { repeats, runAtHour, runAtMinute, dayOfMonth, daysOfWeek, scheduleInterval, repeatsEveryCount } = schedule
  if (repeats === "NEVER") return "-"
  const frequency = localized(`general.${repeats.toLowerCase()}`) // TODO: Dynamic Token
  const time = runAtHour && runAtMinute && getTimeFromSplit(runAtHour, runAtMinute, "LT")
  const timeZone = visibleTimeZone ? getTimeZoneFromWindow() : ""
  return cond([
    [equals("DAILY"), always(<ScheduleSummaryDaily {...{ frequency, time, timeZone }} />)],
    [equals("HOURLY"), always(<ScheduleSummaryHourly {...{ frequency, time, timeZone }} />)],
    [equals("MONTHLY"), always(<ScheduleSummaryMonthly {...{ frequency, time, dayOfMonth, timeZone }} />)],
    [equals("WEEKLY"), always(<ScheduleSummaryWeekly {...{ frequency, time, daysOfWeek, timeZone }} />)],
    [
      equals("ON_SYSTEM_STARTUP"),
      always(<ScheduleSummaryOnActionTriggered {...{ actionText: localized("On System Startup") }} />),
    ],
    [
      equals("ON_USER_LOGIN"),
      always(<ScheduleSummaryOnActionTriggered {...{ actionText: localized("On User Login") }} />),
    ],
    [
      equals("EVERY"),
      always(
        <ScheduleSummaryHourly
          {...{
            frequency,
            time: getIntervalTime({ scheduleInterval, repeatsEveryCount }),
            timeZone: "",
          }}
        />,
      ),
    ],
    [T, always(<EmptySummary />)],
  ])(schedule.repeats)
}

export const TaskScheduleSummaryAgent = ({ schedule, visibleTimeZone = true }) => {
  const { dayOfMonth, scheduleHour, scheduleMinute, scheduleType, weekDays, timeZone: scheduleTimeZone } = schedule
  const frequency = localized(scheduleTypeMap[scheduleType])
  const time = getAgentTime(scheduleHour, scheduleMinute)
  const intervalTime = getIntervalTime({ scheduleHour, scheduleMinute })
  const { abbreviation: timeZone } = getTimeZone(scheduleTimeZone)

  return cond([
    [equals("DAILY"), always(<ScheduleSummaryDaily {...{ frequency, time, timeZone }} />)],
    [equals("WEEKLY"), always(<ScheduleSummaryWeekly {...{ frequency, time, daysOfWeek: weekDays, timeZone }} />)],
    [equals("RUN_ONCE"), always(<ScheduleSummaryDaily {...{ frequency, time, timeZone }} />)],
    [equals("MONTHLY"), always(<ScheduleSummaryMonthly {...{ frequency, time, dayOfMonth, timeZone }} />)],
    [equals("RUN_IMMEDIATELY"), always(<ScheduleSummaryFrequency {...{ frequency }} />)],
    [equals("NO_SCHEDULE"), always(<ScheduleSummaryFrequency {...{ frequency }} />)],
    [equals("INTERVAL"), always(<ScheduleSummaryHourly {...{ frequency, time: intervalTime, timeZone: "" }} />)],
    [equals("ON_SYSTEM_STARTUP"), always(<ScheduleSummaryFrequency {...{ frequency }} />)],
    [equals("ON_USER_LOGIN"), always(<ScheduleSummaryFrequency {...{ frequency }} />)],
  ])(scheduleType)
}
