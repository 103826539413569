import React, { useEffect } from "react"
import styled from "@emotion/styled"
import { FormControl } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSync } from "@fortawesome/pro-regular-svg-icons"
import { StyledRow, StyledCol, StyledFormGroup } from "js/includes/components/Styled/Form"
import {
  localized,
  localizationKey,
  reportErrorAndShowMessage,
  localizedF,
  noRowsRenderer,
  isEnterKey,
} from "js/includes/common/utils"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import { getOrganizationDomainControllers } from "js/includes/common/client"
import { useMountedState } from "js/includes/common/hooks"
import { Box, StyledSpan, Flex } from "js/includes/components/Styled"
import { colors } from "js/includes/common/theme"
import Table from "js/includes/components/Table"
import Tooltip from "js/includes/components/Tooltip"
import Checkbox from "js/includes/components/Checkbox"
import ActiveDirectoryTree from "./ActiveDirectoryTree"

const StyledTableContainer = styled(Box)`
  .ReactVirtualized__Table__headerRow {
    background: ${colors.adDiscoveryHeader};
  }

  .virtualized-table {
    background-color: ${colors.white};
    border: 1px solid ${colors.ninjaLight};
    border-radius: 0px;
    border-left: 0px;
    overflow: hidden;
  }
`

export default function DomainControllerOUPathSelector({
  clientId,
  onSelectDomainController,
  onSelectOuPath,
  domainController: initialDomainController,
  ouPath: initialOuPath,
  recursiveOuPath,
  onRecursiveOuPathChange,
  devices,
  setDevices,
  fetchDevices,
  isLoadingDevices,
  setIsLoadingDevices,
  setIsValidOuPath,
  setIsTypingOuPath,
  isTypingOuPath,
  isValidOuPath,
  ouPathErrorMessage,
}) {
  const [domainController, setDomainController] = useMountedState(initialDomainController)
  const [domainControllersList, setDomainControllersList] = useMountedState([])
  const [isLoadingDomainControllersList, setIsLoadingDomainControllersList] = useMountedState(false)
  const [ouPathNode, setOUPathNode] = useMountedState(initialOuPath ? { ou: initialOuPath } : null)
  const [isRefreshingDevices, setIsRefreshingDevices] = useMountedState(false)
  const [activeOuPath, setActiveOuPath] = useMountedState(initialOuPath)
  const [isLoadingADTree, setIsLoadingADTree] = useMountedState(true)

  const initializeOuPath = () => {
    setOUPathNode({ ou: "" })
    setActiveOuPath("")
    onSelectOuPath("")
  }

  const columns = [
    {
      dataKey: "name",
      width: 1,
      flexGrow: 1,
      label: localizedF("Devices"),
      headerRenderer: () => (
        <Box display="flex" alignItems="center">
          <StyledSpan fontWeight={600} fontSize="14px" paddingLeft="5px">
            {localized("Devices")}
          </StyledSpan>
        </Box>
      ),
      cellRenderer: ({ cellData: name }) => <StyledSpan marginLeft={1}>{name}</StyledSpan>,
    },
  ]

  useEffect(() => {
    async function fetchDomainControllers() {
      try {
        setIsLoadingDomainControllersList(true)
        const domainControllers = await getOrganizationDomainControllers(clientId, { isUp: true })
        setDomainControllersList(domainControllers)
      } catch (error) {
        reportErrorAndShowMessage(error, localizationKey("Error loading domain controllers"))
      } finally {
        setIsLoadingDomainControllersList(false)
      }
    }
    fetchDomainControllers()
  }, [clientId, setDomainControllersList, setIsLoadingDomainControllersList])

  const getNoDevicesMessage = () => {
    if (!ouPathNode) {
      return localizationKey("Select the OU Folder")
    }
    return !isValidOuPath ? ouPathErrorMessage : localizationKey("No devices found")
  }

  return (
    <>
      <StyledRow margin="0" marginBottom={3} display="block" width="100%">
        <StyledCol display="block" xs={5} paddingLeft="0">
          <StyledFormGroup textAlign="left" margin="0 !important">
            <StyledCol marginBottom="10px !important">
              <StyledSpan fontSize="16px">{localized("Domain Controller")}</StyledSpan>
            </StyledCol>
            <StyledCol paddingLeft="1px">
              <SearchableDropDown
                {...{
                  width: "100%",
                  minHeight: "33px",
                  borderRadius: "2px",
                  rowHeight: 35,
                  openOnFocus: true,
                  searchPlaceholderToken: localizationKey("Select"),
                  loading: isLoadingDomainControllersList,
                  valueSelectorKey: "id",
                  searchableKey: "name",
                  value: domainController,
                  options: domainControllersList,
                  onSelect: selected => {
                    setDomainController(selected)
                    onSelectDomainController(selected)
                    setDevices([])
                    setIsLoadingDevices(false)
                    setOUPathNode(null)
                    setActiveOuPath(null)
                    setIsTypingOuPath(false)
                  },
                  disabled: isLoadingDevices,
                }}
              />
            </StyledCol>
          </StyledFormGroup>
        </StyledCol>
        <StyledCol xs={6} display="block" paddingRight="0">
          <StyledFormGroup textAlign="left" margin="0 !important">
            <StyledCol marginBottom="10px !important">
              <StyledSpan fontSize="16px">{localized("OU Path")}</StyledSpan>
            </StyledCol>
            <StyledCol>
              <FormControl
                type="text"
                value={ouPathNode?.ou ?? ""}
                onChange={({ target: { value } }) => {
                  setOUPathNode({ ou: value })
                  setActiveOuPath(value)
                  onSelectOuPath(value)
                  setDevices([])
                  setIsLoadingDevices(false)
                  setIsTypingOuPath(true)
                  setIsValidOuPath(false)
                }}
                onKeyDown={e => {
                  if (isEnterKey(e) && domainController && !isLoadingDevices) {
                    e.preventDefault()
                    if (!ouPathNode) {
                      initializeOuPath()
                    }
                    fetchDevices(domainController, ouPathNode?.ou ?? "", [], recursiveOuPath)
                  }
                }}
                disabled={isLoadingDevices || !domainController}
              />
            </StyledCol>
          </StyledFormGroup>
          <Flex alignItems="center" marginTop={2}>
            <Checkbox
              name="recursiveOuPath"
              checked={recursiveOuPath}
              onChange={e => onRecursiveOuPathChange(e.target.checked)}
              disabled={isLoadingDevices || !domainController || isLoadingADTree}
            />
            <StyledSpan>{localized("Recursive")}</StyledSpan>
            <Tooltip
              token={localizationKey(
                "This will recursively discover devices under all subfolders in the selected OU path.",
              )}
              placement="right"
            />
          </Flex>
        </StyledCol>
        <StyledCol xs={1} justifyContent="center">
          <Box marginTop="38px">
            {domainController && (
              <Box
                cursor="pointer"
                onClick={async () => {
                  if (isLoadingDevices) return
                  if (!ouPathNode) {
                    initializeOuPath()
                  }
                  setIsRefreshingDevices(true)
                  await fetchDevices(domainController, ouPathNode?.ou ?? "", [], recursiveOuPath)
                  setIsRefreshingDevices(false)
                }}
              >
                <Tooltip output={localized("Refresh device list")}>
                  <FontAwesomeIcon icon={faSync} spin={isRefreshingDevices} />
                </Tooltip>
              </Box>
            )}
          </Box>
        </StyledCol>
      </StyledRow>
      {domainController ? (
        <StyledRow display="block">
          <StyledCol xs={6} paddingRight="0">
            <Box
              height="360px"
              overflowX="auto"
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              width="100%"
              border={`1px solid ${colors.ninjaLight}`}
              borderRadius="0px"
              backgroundColor={colors.white}
            >
              <Box
                height="40px"
                backgroundColor={colors.adDiscoveryHeader}
                width="100%"
                display="flex"
                alignItems="center"
                paddingLeft={2}
                fontWeight="600"
                fontSize="14px"
              >
                {localized("Active Directory")}
              </Box>
              <ActiveDirectoryTree
                key={`tree_domain_${domainController.id}`}
                domainController={domainController}
                activeOuPath={activeOuPath}
                setActiveOuPath={setActiveOuPath}
                recursiveOuPath={recursiveOuPath}
                onSelect={node => {
                  setOUPathNode(node)
                  setDevices(node.ouComputers || [])
                  onSelectOuPath(node.ou)
                  setIsValidOuPath(true)
                  setIsTypingOuPath(false)
                }}
                onSelectOuPathNode={node => {
                  setOUPathNode(node)
                  onSelectOuPath(node.ou)
                }}
                onLoadingDevices={loading => {
                  loading && setDevices([])
                  setIsLoadingDevices(loading)
                }}
                setIsLoadingADTree={setIsLoadingADTree}
              />
            </Box>
          </StyledCol>
          <StyledCol xs={6} paddingLeft="0">
            <StyledTableContainer display="flex" flexDirection="column" height={360} width="100%">
              <Table
                {...{
                  columns,
                  list: devices,
                  rowHeight: 40,
                  headerHeight: 40,
                  noRowsRenderer:
                    isTypingOuPath && !isLoadingDevices
                      ? () => (
                          <button
                            type="button"
                            className="btn btn-link p-t-md m-t-xs"
                            onClick={() => {
                              fetchDevices(domainController, ouPathNode?.ou ?? "", [], recursiveOuPath)
                            }}
                          >
                            {localized("Refresh device list")}
                          </button>
                        )
                      : noRowsRenderer(getNoDevicesMessage(), isLoadingDevices, "no-padding"),
                  rowClassName: "table-row p-l-sm no-pointer",
                }}
              />
            </StyledTableContainer>
          </StyledCol>
        </StyledRow>
      ) : (
        <Box marginTop="180px" display="flex" flexDirection="column">
          <StyledSpan fontSize="16px">{localized("There are no device directories to show")}</StyledSpan>
          <StyledSpan fontSize="16px">{localized("Select a Domain Controller to browse directories")}</StyledSpan>
        </Box>
      )}
    </>
  )
}
