import React, { Component } from "react"
import styled from "@emotion/styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFolderOpen, faFolder } from "@fortawesome/pro-solid-svg-icons"
import { reportErrorAndShowMessage } from "js/includes/common/utils"
import { Box, StyledSpan } from "js/includes/components/Styled"
import Tree from "js/includes/components/Tree"
import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import { colors } from "js/includes/common/theme"
import Loading from "js/includes/components/Loading"
import { getOuPathTreeData } from "js/includes/common/client"

const StyledTreeContainer = styled(Box)`
  background-color: ${colors.white};
  .list-actions {
    display: none;
  }
  .folder-icon {
    color: ${colors.ninjaYellow};
  }
`

export default class ActiveDirectoryTree extends Component {
  state = {
    treeRootData: [],
    isLoadingRoot: true,
    loadingChildrenByNodeId: {},
    ouComputersByNodeId: {},
    ouComputersByNodeIdRecursive: {},
    childrenByNodeId: {},
    activeRow: null,
  }

  async componentDidMount() {
    this._isMounted = true
    this.props.setIsLoadingADTree(true)
    const response = await this.getOUChildren({ id: "0", ou: "" })
    if (response?.children?.length) {
      const { children, ouComputers = [] } = response
      this._isMounted &&
        this.setState({ treeRootData: [{ id: "0", name: children[0].domain, ou: "", children: true, ouComputers }] })
    }
    if (this._isMounted) {
      this.setState({ isLoadingRoot: false })
      this.props.setIsLoadingADTree(false)
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  setLoadingChildrenByNodeId = (rowId, loading) => {
    this._isMounted &&
      this.setState(({ loadingChildrenByNodeId }) => ({
        loadingChildrenByNodeId: { ...loadingChildrenByNodeId, [rowId]: loading },
      }))
  }

  getOUChildren = async row => {
    const { domainController, onSelect, recursiveOuPath } = this.props
    if (!domainController) return
    try {
      this.setLoadingChildrenByNodeId(row.id, true)
      const response = await getOuPathTreeData(domainController.id, row.ou, recursiveOuPath)
      const { ou = [], ouComputers = [] } = response
      const children = ou.map((child, i) => ({
        id: `${row.id}-${i}`,
        parent_id: row.id,
        children: child.ouChildren,
        ...child,
      }))
      this._isMounted &&
        this.setState(({ ouComputersByNodeId, ouComputersByNodeIdRecursive, childrenByNodeId }) => ({
          ...(recursiveOuPath
            ? {
                ouComputersByNodeIdRecursive: { ...ouComputersByNodeIdRecursive, [row.id]: ouComputers },
              }
            : {
                ouComputersByNodeId: { ...ouComputersByNodeId, [row.id]: ouComputers },
              }),
          childrenByNodeId: { ...childrenByNodeId, [row.id]: children },
        }))
      if (row.ou === this.props.activeOuPath) {
        onSelect({ ...row, ouComputers })
      }
      return {
        children,
        ouComputers,
      }
    } catch (error) {
      reportErrorAndShowMessage(error, localizationKey("Error loading Active Directory tree"))
    } finally {
      this._isMounted && this.setLoadingChildrenByNodeId(row.id, false)
      this.props.onLoadingDevices(false)
    }
  }

  onGetRoot = () => {
    return this.state.treeRootData
  }

  onGetRowText = row => {
    const { name, ou } = row
    return <span className={`btn-link ${ou === this.props.activeOuPath ? "font-bold" : ""}`}>{name}</span>
  }

  onGetRowStyle = ({ ou }) => ({
    backgroundColor: ou === this.props.activeOuPath ? "ninjaLightBlue" : "none",
  })

  onGetRowChildren = async row => {
    if (this.state.childrenByNodeId[row.id]) {
      return this.state.childrenByNodeId[row.id]
    }
    const response = await this.getOUChildren(row)
    const { children = [] } = response || {}
    return children
  }

  onGetRowIcon = row => (
    <FontAwesomeIcon
      {...{
        icon: row.children && row.expanded ? faFolderOpen : faFolder,
        className: "folder-icon",
      }}
    />
  )

  onRowClickHandler = row => {
    this.props.onSelectOuPathNode(row)
    this.props.setActiveOuPath(row.ou)
    this.setState({ activeRow: row })
    this.setOrFetchOuChildren(row)
  }

  setOrFetchOuChildren = row => {
    const ouComputersCache = this.props.recursiveOuPath
      ? this.state.ouComputersByNodeIdRecursive
      : this.state.ouComputersByNodeId
    if (ouComputersCache[row.id]) {
      this.props.onSelect({ ...row, ouComputers: ouComputersCache[row.id] })
    } else {
      this.props.onLoadingDevices(true)
      if (!this.state.loadingChildrenByNodeId[row.id]) {
        this.getOUChildren(row)
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.recursiveOuPath !== this.props.recursiveOuPath && this.state.activeRow) {
      this.setOrFetchOuChildren(this.state.activeRow)
    }
  }

  render() {
    const {
      onGetRoot,
      onGetRowText,
      onGetRowStyle,
      onGetRowChildren,
      onGetRowIcon,
      onRowClickHandler,
      state: { isLoadingRoot, treeRootData = [] },
    } = this
    if (isLoadingRoot) {
      return (
        <Box paddingTop="11px" fontSize="14px" fontWeight="600">
          <Loading />
        </Box>
      )
    }
    return !treeRootData.length ? (
      <StyledSpan fontSize="14px" fontWeight="600" paddingTop="11px">
        {localized("No data available")}
      </StyledSpan>
    ) : (
      <StyledTreeContainer textAlign="left" height="320px" overflow="auto" width="100%">
        <Tree
          {...{
            rowHeight: 40,
            onGetRoot,
            rowIndent: 10,
            onGetRowText,
            onGetRowChildren,
            onGetRowIcon,
            onGetRowStyle,
            onRowClickHandler,
            rowStyle: {
              paddingLeft: "10px",
              borderBottom: "1px solid",
              borderBottomColor: "ninjaLight",
              cursor: "pointer",
            },
            expandAllRows: false,
          }}
        />
      </StyledTreeContainer>
    )
  }
}
