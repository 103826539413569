import { applySpec, compose, filter, pluck, prop } from "ramda"

import styled from "@emotion/styled"
import { AlertMessage, Button, Modal, PhoneInput, Tabs, Text } from "@ninjaone/components"
import { DownloadIcon } from "@ninjaone/icons"
import { sizer, getTextSize } from "@ninjaone/utils"

import {
  localizationKey,
  localized,
  localizedWith,
  reportErrorAndShowMessage,
  showErrorMessage,
  showSuccessMessage,
  validations,
} from "js/includes/common/utils"
import { Box, Flex } from "js/includes/components/Styled"
import { useMountedState } from "js/includes/common/hooks"
import { downloadEnrollmentFile, sendAndroidEnrollment, sendAppleEnrollment } from "js/includes/common/client"
import { QRCodeImage } from "js/includes/dashboards/mdmEnrollment/QRCodeImage"
import ExternalLink from "js/includes/components/ExternalLink"
import { AppUsersAndContactsDropdown } from "js/includes/ticketing/shared/components"

const StyledDownloadIcon = styled(DownloadIcon)`
  margin-right: ${sizer(1)};
`

const StyledOrderedList = styled.ol`
  padding-left: 0;
  counter-reset: list-counter;
  list-style-type: none;

  & > li {
    display: flex;
    counter-increment: list-counter;

    &::before {
      margin-top: 0.5px;
      margin-right: ${sizer(1)};
      content: counter(list-counter) ".";
      font-size: ${getTextSize("sm")};
      color: ${({ theme }) => theme.colorTextStrong};
    }
  }
`

const mapOptions = field => compose(pluck("uid"), filter(prop(field)))

const InstructionText = ({ token, children }) => (
  <li>
    <Text {...{ token }} size="sm" color="colorTextStrong" textWrap>
      {children}
    </Text>
  </li>
)

const InstructionTitle = ({ token }) => <Text {...{ token }} color="colorTextStrong" bold />

const QrCode = ({ data, organization, location, isIos, nodeRoleId, isPersonalAndWorkUsage, connectionId }) => {
  const [phoneNumber, setPhoneNumber] = useMountedState("")
  const [validPhoneNumber, setValidPhoneNumber] = useMountedState(false)
  const [emailValues, setEmailValues] = useMountedState({ uids: [], emails: [] })
  const [isProcessing, setIsProcessing] = useMountedState(false)

  const onSendEnrollment = async type => {
    setIsProcessing(true)
    try {
      const { emails, uids } = emailValues
      const payload = type === "sms" ? { phoneNumbers: [phoneNumber] } : { emails, uids }
      if (isIos) {
        const { code } = data
        await sendAppleEnrollment({
          pushCertificateId: connectionId,
          deliverOption: type,
          code,
          payload,
        })
      } else {
        const { enrollmentUuid } = data
        await sendAndroidEnrollment({
          enterpriseId: connectionId,
          enrollmentTokenUid: enrollmentUuid,
          deliverOption: type,
          payload,
        })
      }
      showSuccessMessage(localized("mdm.actions.sentSuccess"))
    } catch (error) {
      if (error.resultCode === "client_push_certificate_expired") {
        showErrorMessage(localized("An error has occurred. The Apple Push Notification certificate has expired."))
      } else {
        reportErrorAndShowMessage(error)
      }
    } finally {
      setIsProcessing(false)
    }
  }

  const downloadAppleEnrollmentFile = () =>
    downloadEnrollmentFile({
      clientId: organization.id,
      locationId: location.value,
      nodeRole: nodeRoleId,
      pushCertificateId: connectionId,
    })

  const handleUpdatePhoneNumber = (value, isValid) => {
    setPhoneNumber(value)
    setValidPhoneNumber(isValid)
  }

  const handleResetPhoneNumber = () => {
    setPhoneNumber("")
    setValidPhoneNumber(false)
  }

  return (
    <Flex>
      <QRCodeImage {...{ isIos }} qrCode={data.qrCode} codeValue={data.tokenValue} jsonValue={data.qrCodeJson} />
      <Box width="100%" marginLeft={sizer(4)}>
        <Box marginBottom={sizer(2)}>
          {isIos ? (
            <>
              <InstructionTitle token={localizationKey("Add device")} />
              <StyledOrderedList>
                <InstructionText token={localizationKey("Scan the QR code.")} />
                <InstructionText token={localizationKey("Follow on-screen instructions.")} />
              </StyledOrderedList>
            </>
          ) : (
            <>
              {isPersonalAndWorkUsage && (
                <>
                  <InstructionTitle token={localizationKey("Add a personally owned device")} />
                  <StyledOrderedList>
                    <InstructionText>
                      {localizedWith(
                        "Install the <%appLink>Android Device Policy (ADP)<%> application on the device.",
                        {
                          appLink: ({ localizedText }) => (
                            <ExternalLink url="https://play.google.com/store/apps/details?id=com.google.android.apps.work.clouddpc">
                              {localizedText}
                            </ExternalLink>
                          ),
                        },
                      )}
                    </InstructionText>
                    <InstructionText
                      token={localizationKey("Open ADP, accept the camera permission, and scan the QR code")}
                    />
                    <InstructionText token={localizationKey("Follow on-screen instructions.")} />
                  </StyledOrderedList>
                </>
              )}

              <InstructionTitle token={localizationKey("Add a company owned device")} />
              <StyledOrderedList>
                <InstructionText token={localizationKey("Factory reset the Android device")} />
                <InstructionText token={localizationKey("Tap 6 times on the Welcome screen (don’t press continue)")} />
                <InstructionText token={localizationKey("Scan the QR code")} />
                <InstructionText token={localizationKey("Follow on-screen instructions")} />
              </StyledOrderedList>
            </>
          )}
        </Box>
        <Tabs
          onChange={handleResetPhoneNumber}
          tabs={[
            ...(isIos
              ? [
                  {
                    labelToken: localizationKey("Download"),
                    renderer: () => (
                      <Box marginTop={sizer(3)}>
                        <Button
                          variant="tertiary"
                          onClick={downloadAppleEnrollmentFile}
                          id="download-ios-enrollment-button"
                        >
                          <StyledDownloadIcon />
                          <Text
                            lineHeight="inherit"
                            token={localizationKey("Download Apple Enrollment File")}
                            bold
                            size="sm"
                          />
                        </Button>
                      </Box>
                    ),
                  },
                ]
              : []),
            {
              labelToken: localizationKey("Send by email"),
              renderer: () => (
                <Flex width="100%" marginTop={sizer(3)} marginBottom="1px" alignItems="end">
                  <Flex flex={1} paddingRight={sizer(2)}>
                    <AppUsersAndContactsDropdown
                      {...{
                        id: "send-email",
                        clientId: organization.id,
                        onSelect: values => {
                          const data = applySpec({
                            emails: mapOptions("isNew"),
                            uids: mapOptions("naturalId"),
                          })(values)
                          setEmailValues(data)
                        },
                        isCreatable: true,
                        shouldCreate: value => validations.email(value).success,
                        isMulti: true,
                        ariaAttributes: { "aria-labelledby": "send-email" },
                        ariaInputAttributes: { "aria-labelledby": "send-email" },
                        disabled: isProcessing,
                        useSelectStyling: true,
                      }}
                    />
                  </Flex>
                  <Button
                    labelToken={localizationKey("Send")}
                    variant="primary"
                    disabled={isProcessing || (!emailValues?.uids?.length && !emailValues?.emails?.length)}
                    onClick={() => onSendEnrollment("email")}
                    id="mobile-send-by-email-button"
                  />
                </Flex>
              ),
            },
            ...(isIos || isPersonalAndWorkUsage
              ? [
                  {
                    labelToken: localizationKey("Send by SMS"),
                    renderer: () => (
                      <Box paddingTop={sizer(4)}>
                        {isPersonalAndWorkUsage && !isIos && (
                          <AlertMessage variant="info">
                            {localizedWith(
                              "This will only work for personally owned work profile (BYOD) enrollment. For company owned (COPE) please use the <%sendByEmail>Send by Email<%> option.",
                              { sendByEmail: ({ localizedText }) => <strong>{localizedText}</strong> },
                            )}
                          </AlertMessage>
                        )}
                        <Flex width="100%" marginTop={sizer(3)} marginBottom="1px">
                          <Flex flex={1} paddingRight={sizer(2)}>
                            <PhoneInput
                              value={phoneNumber}
                              ariaLabel={localized("Phone number")}
                              onChange={handleUpdatePhoneNumber}
                              disabled={isProcessing}
                            />
                          </Flex>
                          <Button
                            labelToken={localizationKey("Send")}
                            variant="primary"
                            disabled={isProcessing || !phoneNumber || !validPhoneNumber}
                            onClick={() => onSendEnrollment("sms")}
                            id="mobile-send-by-sms-button"
                          />
                        </Flex>
                      </Box>
                    ),
                  },
                ]
              : []),
          ]}
        />
      </Box>
    </Flex>
  )
}

const MobileQRModal = ({
  unmount,
  data,
  organization,
  location,
  isIos,
  nodeRoleId,
  isPersonalAndWorkUsage,
  connectionId,
}) => {
  return (
    <Modal
      {...{
        size: "md",
        unmount,
        withCloseX: true,
        titleGroup: {
          TitleComponent: () => <Text token={localizationKey("Successfully generated")} bold />,
        },
        buttonRenderer: () => <></>,
      }}
    >
      <QrCode {...{ data, isIos, organization, location, nodeRoleId, isPersonalAndWorkUsage, connectionId }} />
    </Modal>
  )
}

export default MobileQRModal
